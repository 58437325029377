import { patchState, signalStore, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { exhaustMap, filter, switchMap, take, tap } from "rxjs";
import { inject } from "@angular/core";
import { ApiService } from "../../api/services/api.service";

type EnvironmentState = {
    client_id: string,
    webhook_url: string,
    amo: {
        domain: string,
        account_id: number
    },
    isDataLoaded: boolean
}

const initialState: EnvironmentState = {
    client_id: "",
    webhook_url: "",
    amo: {
        domain: "",
        account_id: 0
    },
    isDataLoaded: false
}

export const EnvironmentStore = signalStore(
    { providedIn: 'root'},
    withState(initialState),
    withMethods((store, apiService = inject(ApiService)) => ({
        loadClientData: rxMethod<void>(
            exhaustMap(() => {
                return apiService.apiAccountGet().pipe(
                    tap({
                        next: (accountData: Partial<EnvironmentState>) => {
                            patchState(store, {
                                client_id: accountData.client_id,
                                webhook_url: accountData.webhook_url,
                                amo: accountData.amo
                            });
                        },
                        error: console.error,
                    })
                )
            })
        ),
    })),
)
