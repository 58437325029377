import { WebhookResponse } from "../../api/models/webhook-response";
import { patchState, signalStoreFeature, type, withComputed, withMethods, withState } from "@ngrx/signals";
import { EntityId } from "@ngrx/signals/entities";
import { computed, inject, Signal } from "@angular/core";
import { ApiService } from "../../api/services";
import { HelperFunctions } from "../../helpers/HelperFunctions";

export type WebhookRequest = {
    'request_id': string;
    'created_at': string;
    'body': string;
    'ip': string;
    'content_type': string;
}

export function withHooksRequests<E extends WebhookResponse>() {
    return signalStoreFeature(
        {
            state: type<{
                entityMap: Record<EntityId, E>,
                ids: EntityId[],
                selected_id: string
            }>(),
            signals: type<{
                entities: Signal<WebhookResponse[]>,
            }>(),
            methods: type<{}>()
        },
        withState({
            requests: [] as WebhookRequest[],
            allRequestsLoaded: false as Boolean,
            isRequestLoaded: false as Boolean,
            lastRequestBody: {} as any,
        }),
        withComputed(({ requests }) => ({
            lastRequestBody: computed(() => {
                let req: WebhookRequest[] = requests();
                let obj: any = {};
                if (req.length) {
                    obj = HelperFunctions.parseJson(req[0].body, {});
                }
                return obj;
            }),
        })),
        withMethods((store, apiService = inject(ApiService)) => ({
            clearRequests: () => {
                patchState(store, {
                    requests: [],
                    allRequestsLoaded: false,
                    selected_id: ""
                });
            },
        }))
    )
}
