import { signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { withEntities } from "@ngrx/signals/entities";
import { WebhookResponse } from "../../api/models/webhook-response";
import { withHooksApi } from "./with-hooks-api.feature";
import { withHooksRequests } from "./with-hooks-requests";
import { computed, inject } from "@angular/core";
import { EnvironmentStore } from "../environment/environment.store";

type HookState = {
    isLoaded: boolean,
    selected_id: string
};

const initialHookState: HookState = {
    isLoaded: false,
    selected_id: '',
};

export const HooksStore = signalStore(
    { providedIn: 'root' },
    withState(initialHookState),
    withEntities<WebhookResponse>(),
    withHooksRequests(),
    withHooksApi(),
    withMethods((store, envStore = inject(EnvironmentStore)) => ({
        buildHookUrl(hookId: string): string {
            return `${envStore.webhook_url()}${envStore.client_id()}/${hookId}`;
        },
        extractHookId(url: string) : string {
            let parts: string[] = url.split('/');
            return parts[parts.length - 1];
        }
    })),
    withComputed((store) => ({
        getSelectedHook: computed(() => {
            return store.entityMap()[store.selected_id()];
        }),
    }))
)
