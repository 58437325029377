import { WebhookResponse } from "../../api/models/webhook-response";
import { patchState, signalStoreFeature, type, withMethods } from "@ngrx/signals";
import { addEntity, EntityId, removeEntity, setAllEntities, updateEntity } from "@ngrx/signals/entities";
import { inject, Signal } from "@angular/core";
import { ApiService } from "../../api/services/api.service";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { concatMap, distinctUntilChanged, exhaustMap, filter, mergeMap, pipe, tap } from "rxjs";
import { tapResponse } from "@ngrx/operators";
import { WebhookCreateRequest } from "../../api/models/webhook-create-request";
import { WebhookUpdateRequest } from "../../api/models/webhook-update-request";
import { WebhookRequest } from "./with-hooks-requests";

export function withHooksApi<E extends WebhookResponse>() {
    return signalStoreFeature(
        {
            state: type<{
                entityMap: Record<EntityId, E>,
                ids: EntityId[],
            }>(),
            signals: type<{
                entities: Signal<WebhookResponse[]>
            }>(),
            methods: type<{} >()
        },
        withMethods((store: any, apiService: ApiService = inject(ApiService)) => ({
            loadHooks: rxMethod<void>(
                exhaustMap(() => {
                    return apiService.apiWebhooksGet().pipe(
                        tap({
                            next: (hooks: WebhookResponse[]) => {
                                patchState(store, setAllEntities(hooks));
                                patchState(store, { isLoaded: true })
                            },
                        })
                    );
                })
            ),
            loadHookById: rxMethod<string>(
                pipe(filter((id) => !!id && !store.entityMap()[id]), concatMap((id) => {
                    return apiService.apiWebhooksIdGet({
                        id
                    }).pipe(
                        tapResponse({
                            next: (hook: WebhookResponse) => patchState(store, addEntity(hook)),
                            error: console.error,
                        })
                    );
                }))
            ),
            deleteHookById: rxMethod<string>(
                pipe(
                    filter((id) => !!id && store.entityMap()[id]),
                    distinctUntilChanged(),
                    mergeMap((id) => {
                    return apiService.apiWebhooksIdDelete({ id }).pipe(
                        tapResponse({
                            next: () => patchState(store, removeEntity(id)),
                            error: console.error
                        })
                    )
                }))
            ),
            createHook: rxMethod<WebhookCreateRequest>(
                pipe(filter((hook) => !!hook), concatMap((hook) => {
                    return apiService.apiWebhooksPost({ body: hook }).pipe(
                        tapResponse({
                            next: (hook: WebhookResponse) => patchState(store, addEntity(hook)),
                            error: console.error
                        })
                    )
                }))
            ),
            patchPropertyById: rxMethod<{ id: string, property: any, value: any }>(
                pipe(filter((info) => !!info.id && store.entityMap()[info.id]), mergeMap((info) => {
                    return apiService.apiWebhooksIdPatch({
                        id: info.id,
                        body: {
                            [info.property]: info.value
                        }
                    }).pipe(
                        tapResponse({
                            next: (webhook: WebhookResponse) => patchState(store, updateEntity({ id: info.id, changes: { ...webhook }})),
                            error: console.error
                        })
                    )
                }))
            ),
            updateHookById: rxMethod<{ id: string, updateHook: WebhookUpdateRequest }>(
                pipe(
                    filter((info) => !!info.id && store.entityMap()[info.id]),
                    mergeMap((info) => {
                    return apiService.apiWebhooksIdPatch({
                        id: info.id,
                        body: info.updateHook
                    }).pipe(
                        tapResponse({
                            next: (webhook: WebhookResponse) => patchState(store, updateEntity({ id: info.id, changes: { ...webhook }})),
                            error: console.error
                        })
                    )
                }))
            ),
            loadHookRequestsById: rxMethod<{ id: string, limit?: number, offset?: number }>(
                pipe(
                    filter((params) => !!params.id && store.entityMap()[params.id]),
                    concatMap((params) => {
                        patchState(store, { isRequestLoaded: false })
                        return apiService.apiWebhooksIdRequestsGet(params).pipe(
                            tapResponse({
                                next: (newRequests: WebhookRequest[]) => {
                                    if (newRequests.length) {
                                        patchState(store, { requests: [ ...(store.requests()), ...newRequests ] });
                                        if (params.limit && (newRequests.length < params.limit)) {
                                            patchState(store, { allRequestsLoaded: true })
                                        }
                                    } else {
                                        patchState(store, { allRequestsLoaded: true })
                                    }
                                },
                                error: console.error,
                                finalize: () => {
                                    patchState(store, { isRequestLoaded: true })
                                }
                            })
                        )
                }))
            )
        }))
    )
}
